import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../containers/Hosting/Navbar';
import Footer from '../../containers/Hosting/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../components/seo';
import TopRibbon from '../../containers/Hosting/TopRibbon';

import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Extlink from 'common/src/components/Link';
import Container from 'common/src/components/UI/Container';

import ContactSection2 from '../../containers/Hosting/Contact2/Misc';
import Achievementimage from 'common/src/assets/image/hosting/news/news_page.png';
import founder1 from 'common/src/assets/image/hosting/Team/Raghu.jpg';
import founder2 from 'common/src/assets/image/hosting/Team/Baskar.jpg';
import MiscWrapper from './misc.style';
import { useEffect, useState } from 'react';

const MiscSection1 = ({
  name,
  sectionWrapper,
  row,
  row1,
  col,
  col2,
  title,
  description,
  centeritems,
  bold_description,
  topmargin,
  linktext,
  buttonbox,
  innerrow,
  centertext,
  center_small_text,
  margintop,
  centertopic,
}) => {
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title=" Rapid Acceleration Partners Secures Pre-Series A Funding from Artha99 AIF Investment Advisors to Democratize AI-First Hyperautomation Solutions"
          description="Rapid Acceleration Partners (RAP), an AI-first hyperautomation platform company, announces Pre-Series A funding from Artha99 AIF Investment advisors. RAP aims to help companies improve productivity and efficiency using its low-code hyperautomation platforms RAPFlow and RAPBot. Join RAP on its mission to democratize AI and intelligent process automation for companies of all sizes."
        />
        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="CaseStudyPage">
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            releasedClass="sticky-nav-inactive"
          >
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <MiscWrapper>
            <Box {...sectionWrapper}>
              <Container>
                <Box {...row1}>
                  <Box {...innerrow}>
                    <Fade left cascade>
                      <Box>
                        <Box {...row}>
                          <Box {...col}>
                            <a
                              href="https://www.analyticsinsight.net/The-10-Most-Valuable-Digital-Transformation-Companies-2020/#page=53"
                              target="_blank"
                            >
                              <img
                                src={Achievementimage}
                                style={{ maxWidth: '100%' }}
                                alt="analytics insight magazine certificate"
                              />
                            </a>
                          </Box>
                        </Box>
                        <Heading
                          {...title}
                          content="Rapid Acceleration Partners Named Among Analytics Insights Magazine’s “Top 10 Most Valuable Digital Transformation Companies”"
                        />

                        <Text
                          {...description}
                          content="We are thrilled to announce that Rapid Acceleration Partners (RAP) has been recognized as one of the "
                        />
                        <Text
                          {...bold_description}
                          content="“Top 10 Most Valuable Digital Transformation Companies” "
                        />
                        <Text
                          {...description}
                          content=" by Analytics Insights Magazine for the year 2020. This award is a reinforcement of RAP’s committment to making Intelligent Process Automation (IPA) work for our clients. Have worked across"
                        />
                        <Text
                          {...bold_description}
                          content="  6 industries, processing 7 million+ pieces of content, saving 70% of manual effort"
                        />
                        <Text {...description} content="and" />
                        <Text
                          {...bold_description}
                          content=" reducing operational costs by 75% "
                        />
                        <Text
                          {...description}
                          content="for clients worldwide. Read more about RAP’s award in Analytics Insights Magazine "
                        />
                        <Extlink
                          href="https://www.analyticsinsight.net/The-10-Most-Valuable-Digital-Transformation-Companies-2020/#page=53"
                          {...linktext}
                        >
                          here.
                        </Extlink>

                        <Text
                          {...description}
                          content=" A big thank you to Analytics Insights Magazine for recognizing us and featuring us for 2020!"
                        />
                        <Text
                          {...topmargin}
                          content="RAP would like to say a big thank you to our team. This monumental success would not have been possible without their hard work, dedication and commitment to excellence. RAP also extends our gratitude to our customers and investors who put their faith in us."
                        />
                        <Text
                          {...topmargin}
                          content="Analytics Insight Magazine is the world’s First Print and Digital Publication focused on Artificial Intelligence, Big Data and Analytics. With a vision to be “the trusted and premier source of information and analysis on Artificial Intelligence, Big Data Analytics to maximize the strategic value of organizations worldwide,” the magazine focuses on disruptive technology and features opinions and views from top leaders and executives in the industry who share their journey, experiences, success stories, and knowledge to grow profitable businesses. It curates leading technology stories, reports, thought leadership pieces and developments, getting you directly to the core of what’s trending."
                        />
                        <Text
                          {...topmargin}
                          content="“RAP was founded with a vision to democratize AI and IPA for everyone, opening the door to the future of hyperautomation. It has been quite a ride since our early days with a handful of pilot projects to carving out a niche as a recognized player in the industry over the last 3 years. It is truly gratifying to see the hard work and long nights paying off. More than that, it is invigorating to see what the future holds for us and our clients in this new, exciting chapter of disruptive innovation that is changing how companies work.” Raghuraman Ramamurthy, Founder & CEO, RAP"
                        />
                        <Text
                          {...topmargin}
                          content="“I’m delighted that RAP has been recognized among the Top 10 Most Valuable Digital Transformation Companies in 2020. Automation is the touchstone of the future, and our mission is to empower companies worldwide with hyperautomation. The future of this disruptive technology is exciting and RAP will play a significant role as a digital transformation partner for companies worldwide.” Baskar Agneeswaran, Founder & Director, RAP"
                        />
                      </Box>
                    </Fade>

                    {/* <Box {...row}>
                      <Box {...col2}>
                        <Fade left cascade>
                          <img
                            src={founder1}
                            style={{ width: '150px', display: 'block' }}
                          />
                          <Text
                            {...centertext}
                            content="Raghuraman Ramamurthy"
                          />
                          <Text
                            {...center_small_text}
                            content="Founder & CEO, RAP."
                          />
                          <Text
                            {...centeritems}
                            content="“RAP was founded with a vision to democratize AI and IPA for everyone, opening the door to the future of hyperautomation. It has been quite a ride since our early days with a handful of pilot projects to carving out a niche as a recognized player in the industry over the last 3 years. It is truly gratifying to see the hard work and long nights paying off. More than that, it is invigorating to see what the future holds for us and our clients in this new, exciting chapter of disruptive innovation that is changing how companies work.” Raghuraman Ramamurthy, Founder & CEO, RAP."
                          />
                        </Fade>
                      </Box>
                      <Box {...col2}>
                        <Fade right cascade>
                          <img
                            src={founder2}
                            style={{ width: '150px', display: 'block' }}
                          />
                          <Text {...centertext} content="Baskar Agneeswaran" />
                          <Text
                            {...center_small_text}
                            content="Founder & Director, RAP"
                          />
                          <Text
                            {...centeritems}
                            content="“I’m delighted that RAP has been recognized among the Top 10 Most Valuable Digital Transformation Companies in 2020. Automation is the touchstone of the future, and our mission is to empower companies worldwide with hyperautomation. The future of this disruptive technology is exciting and RAP will play a significant role as a digital transformation partner for companies worldwide.” Baskar Agneeswaran, Founder & Director, RAP"
                          />
                        </Fade>
                      </Box>
                    </Box> */}
                    <Box {...margintop}>
                      {/* <Heading {...centertopic} content="About us" /> */}
                      <Text
                        {...description}
                        content="RAP provides practical AI solutions for digital business transformation leveraging IPA, RPA and hyperautomation. With a view to democratize AI, RAP has developed disruptive, innovative products RAPFlow – a next-gen, full-lifecycle AI orchestration platform for building content intelligence solutions, and RAPBot – our RPA tool for end-to-end automation. When used in tandem they provide game-changing, end-to-end hyperautomation for processes – this enables greater productivity, capacity and efficiency while reducing operating costs. To date, RAP has worked with several companies across various industries helping them jump to lightspeed and rev up operational efficiency by automating repetitive processes. "
                      />
                    </Box>
                    <Box {...row}>
                      <Box {...buttonbox}>
                        <a
                          href="https://www.analyticsinsight.net/The-10-Most-Valuable-Digital-Transformation-Companies-2020/#page=53"
                          className="btn btn_tertiary"
                          target="_blank"
                        >
                          <span className="btn-text">View Article</span>
                        </a>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Box>
          </MiscWrapper>
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

MiscSection1.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

MiscSection1.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: '100px',
    pb: ['0', '0', '50px', '50px', '50px'],
    id: 'misc_section',
  },
  row1: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
  },
  innerrow: {
    width: ['100%', '100%', '100%', '70%', '70%'],
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '100%', '100%'],
  },
  col2: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  title: {
    fontSize: ['30px', '30px', '35px', '35px', '35px'],
    fontWeight: 'bold',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '30px',
    textAlign: 'center',
    className: 'heading',
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '10px',
    display: 'inline',
  },
  bold_description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    fontWeight: 'bold',
    mb: '10px',
    display: 'inline',
  },
  centeritems: {
    textAlign: 'justify',
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '10px',
  },
  centertext: {
    textAlign: 'center',
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '8px',
  },
  center_small_text: {
    textAlign: 'center',
    fontSize: ['14px', '14px', '14px', '14px', '14px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '10px',
  },
  topmargin: {
    mt: '15px',
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '10px',
  },
  linktext: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    color: 'blue',
  },
  buttonbox: {
    width: [1, 1, 0.2, 0.2, 0.2],
    flexBox: true,
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    mt: '20px',
  },
  margintop: {
    mt: '50px',
  },
  centertopic: {
    textAlign: 'center',
  },
};

export default MiscSection1;
